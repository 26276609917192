













































































































































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      loading: true,
      paginate: {
        keywords: '',
        page: 1,
        size: 10,
        total: 0,
        orderBy: {},
      },
      entities: [],
      selection: [],
      showDialog: false,
      form: {
        goods: '',
      },
      config: {},
      success: 0,
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      const res = await this.$http.request({
        url: 'goods',
        method: 'get',
        params: this.paginate,
      })
      this.entities = res.data.data.data
      this.loading = false
      this.paginate.total = res.data.data.meta.total
      this.config = res.data.config
      this.success = res.data.success
    },
    resetSearch() {
      this.paginate.keywords = ''
      this.paginate.page = 1
      this.fetch()
    },
    handleSizeChange(size: number) {
      this.paginate.size = size
      this.fetch()
    },
    handleCurrentChange(page: number) {
      this.paginate.page = page
      this.fetch()
    },
    handleSortChange({ prop, order }: { prop: any; order: any }) {
      if (order) {
        this.paginate.orderBy = {
          prop,
          order: order.replace('ending', ''),
        }
      } else {
        this.paginate.orderBy = {}
      }
      this.fetch()
    },
    handleSelectionChange(val: any) {
      this.selection = val.map((i: any) => i.id)
    },
    handleRemove(row: any) {
      this.$confirm(`此操作将永久删除"${row.id}", 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$http.delete(`goods/${row.id}`)
        this.$message.success('删除成功')
        this.fetch()
      })
    },
    handleImport() {
      this.showDialog = true
    },
    async handleDownloadTemplate() {
      const response = await this.$http.post(
        'goods/template',
        {},
        {
          responseType: 'arraybuffer',
        },
      )
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      })
      let downloadElement = document.createElement('a')
      let href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `table.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    },
    async handleGoodsImportSubmit() {
      await this.$http.post('goods/import/save', {
        goods: this.form.goods,
      })
      this.$message.success('导入成功')
      this.form.goods = ''
      this.showDialog = false
      this.fetch()
    },
    handleUploadError(error: any) {
      this.$message.error(JSON.parse(error.message).error)
    },
    afterImport(data: any[]) {
      if (!data?.length) return
      this.form.goods = data
        .map((i: Array<any>) => i.join(','))
        .join('\n')
        .trim()
    },
    async handleSelectionDelete() {
      if (!this.selection.length)
        return this.$message.error('请选择要删除的商品')

      this.$confirm('商品删除后将不能再恢复，确认要删除吗?', {
        type: 'warning',
      }).then(async () => {
        await this.$http.delete('goods/selection/delete', {
          params: {
            selection: this.selection,
          },
        })
        this.$message.success('删除成功')
        this.fetch()
      })
    },
    async handleUpdateAll() {
      await this.$http.put('goods/update/all')
      this.$message.success('商品正在更新中')
      this.fetch()
    },
    async handleUpdateOne(row: any) {
      await this.$http.put(`goods/update/${row.id}`)
      this.$message.success('商品正在更新中')
    },
  },
})
